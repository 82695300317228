module.exports = {
    QUALITIES_OPTIONS: [
        { value: 0, text: "---" },
        { value: 5, text: "Legendary" },
        { value: 4, text: "Epic" },
        { value: 3, text: "Rare" },
        { value: 2, text: "Good" },
        { value: 1, text: "Normal" },
    ],
    QUALITIES: {
        NORMAL: 1,
        GOOD: 2,
        RARE: 3,
        EPIC: 4,
        LEGENDARY: 5,
    },
    QUALITY_NAMES: ['Normal', 'Good', 'Rare', 'Epic', 'Legendary'],
    ATTACHED_NFT_OPTIONS: [
        { value: 0, text: "---" },
        { value: 1, text: "True" },
        { value: 2, text: "False" },
    ],
}
