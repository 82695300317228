<template>
  <div class="page-content">
    <page-breadcrumb title="Nft Image Pool" class="mb-3" />

    <b-card>
      <b-row class="mb-50">
        <b-col md="4">
          <b-form-group label="Image name (Without extension)" label-for="url_name">
            <b-form-input id="url_name" type="text" placeholder="Image name (Without extension)"
              v-model="filter.url_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Quality" label-for="quality">
            <b-form-select
              v-model="filter.quality"
              :options="QUALITIES_OPTIONS"
              id="quality"
              type="text"
              placeholder="Legendary"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group label="Attach to an NFT" label-for="is_attached_nft">
            <b-form-select
              v-model="filter.is_attached_nft"
              :options="ATTACHED_NFT_OPTIONS"
              id="is_attached_nft"
              type="text"
              placeholder="Legendary"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <data-table-ssr id="image_list" ref="image_list" :limit-base="9" pagination
        :columns="image_table_fields" :get-list-fn="getList"
      />
    </b-card>

  </div>
</template>

<script>
import service from "../service";
import { QUALITIES_OPTIONS, ATTACHED_NFT_OPTIONS } from "./../constants"
import _ from 'lodash'

const image_table_fields = [
  { label: 'Image', field: 'url', input_type: 'image' },
  { label: 'Image With Bg', field: 'url_bg', input_type: 'image' },
  { label: 'Quality', field: 'quality', input_type: 'select', options: QUALITIES_OPTIONS },
  { label: 'NFT IDS', field: 'nft_ids' },
  { label: 'Skin', field: 'skin' },
  { label: 'Head', field: 'head' },
  { label: 'Face', field: 'face' },
  { label: 'Body', field: 'body' },
  { label: 'Background', field: 'background' },
];

export default {
  data() {
    return {
      image_table_fields,
      QUALITIES_OPTIONS,
      ATTACHED_NFT_OPTIONS,
      filter: { url_name: null, quality: 0, is_attached_nft: 0 },
    };
  },
  watch: {
    filter: {
      handler() {
        this.$refs.image_list.getList();
      },
      deep: true,
    },
  },
  methods: {
    async getList({ limit, page, query, searchTerm }) {
      let filter = _.omitBy(this.filter, (value) => value === null);
      let response = await service.getList({
        query: JSON.stringify({ ...query, ...filter }),
        page, limit
      });
      let list = [], total = 0;
      if (response.data.type === "DATA") {
        list = response.data.data.list;
        total = response.data.data.total;
      }
      return { list, total };
    },

  },
};
</script>
